@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/NotoSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Bold.woff") format("woff"),
    url("./assets/fonts/NotoSans-Bold.ttf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/NotoSans-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/NotoSans-SemiBold.ttf") format("opentype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/NotoSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Medium.woff") format("woff"),
    url("./assets/fonts/NotoSans-Medium.ttf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/NotoSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Regular.woff") format("woff"),
    url("./assets/fonts/NotoSans-Regular.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "NotoSans";
  src: url("./assets/fonts/NotoSans-Light.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Light.woff") format("woff"),
    url("./assets/fonts/NotoSans-Light.ttf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  font-family: "NotoSans";
  line-height: 1.6;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
input,
select,
button {
  font-family: inherit;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
}

textarea {
  font-family: inherit !important;
}

/**
* Rangeslider
*/
.rangeslider {
  margin: 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 7px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #e85b2e;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

.tox-tiny-mce-themis {
  border-radius: 20px !important;
}
.tox-tiny-mce-themis .tox-statusbar__branding {
  display: none;
}
.tox-tiny-mce-themis .tox-statusbar {
  padding: 0 18px !important;
}

hr {
  margin: 50px 0 !important;
  border: none;
  border-top: 2px dashed #eee;
}

[contenteditable="true"] {
  -webkit-user-select: text !important;
  user-select: text !important;
  cursor: text !important;
  outline: none;
}
